import { useRouter } from 'next/router';
import { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { sessionStorageService } from '../service/storage';

interface HistoryListContextProps {
  historyList: string[];
  setHistoryList(data: string[]): void;
  getPreviousValidPath({ ignorePrefixes }: { ignorePrefixes: string[] }): string;
}

const HistoryListContext = createContext<HistoryListContextProps>({} as HistoryListContextProps);

interface IProps {
  children: ReactNode;
}

export const HistoryListProvider = ({ children }: IProps) => {
  const { asPath } = useRouter();
  const { getStorage, setStorage } = sessionStorageService('HISTORY_LIST');
  const [historyList, setHistoryList] = useState<string[]>((getStorage() as string[]) || []);

  useEffect(() => {
    setHistoryList((previousHistory) => {
      if (previousHistory[previousHistory.length - 1] === asPath) {
        return previousHistory;
      }

      const newHistory = [...previousHistory, asPath];

      if (newHistory.length > 10) {
        newHistory.shift();
      }

      return newHistory;
    });
  }, [asPath]);

  const getPreviousValidPath = ({ ignorePrefixes = [] }): string => {
    for (let i = historyList.length - 1; i >= 0; i--) {
      const currentPath = historyList[i];
      if (!ignorePrefixes?.some((ignorePrefix) => currentPath.indexOf(ignorePrefix) === 0)) {
        return currentPath;
      }
    }
    return null;
  };

  useEffect(() => {
    if (!historyList.length) return;
    setStorage(historyList);
  }, [historyList]);

  return (
    <HistoryListContext.Provider
      value={{
        historyList,
        setHistoryList,
        getPreviousValidPath,
      }}
    >
      {children}
    </HistoryListContext.Provider>
  );
};

export function useHistoryList(): HistoryListContextProps {
  const context = useContext(HistoryListContext);
  return context;
}
