import { createContext, useContext, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { isMobileByUserAgent } from '@/shared/utils/utils';
import useIssueAnonymousToken from '@/lib/hooks/useIssueAnonymousToken';

type ProviderType = {
  isMobile?: boolean;
  userAgent?: string;
};

const Context = createContext<ProviderType>({});
const Provider = (props: any) => {
  const { value, children } = props;
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean>(isMobileByUserAgent(value?.userAgent) || false);

  const { issueAnonymousToken } = useIssueAnonymousToken();

  useEffect(() => {
    issueAnonymousToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsMobile(isMobileByUserAgent(value?.userAgent ?? navigator?.userAgent) || width < 780);
  }, [value?.userAgent, width]);

  const exposed = {
    isMobile,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useConfigProvider = () => useContext(Context);

export default Provider;
